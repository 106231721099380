import React, { useEffect, useState, useCallback } from "react"
import * as styles from "./list.module.css"
import Pagenation from "components/tools/pagination.js"
import Layout from "components/common/layout.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getYourStoryListAsync } from "store/modules/yourStoryReducer.js"
import Story from "components/story/story"
import OrderMenu from "components/tools/orderMenu"
import FreeBadge from "components/tools/freeBadge"
import queryString from "query-string"
const List = props => {
  const dispatch = useDispatch()
  const getYourStoryList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getYourStoryListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )

  const [order, setOrder] = useState(1)

  const { yourStoryList, total } = useSelector(
    ({ yourStoryReducer }) => yourStoryReducer,
    shallowEqual
  )
  const { page } = queryString.parse(props.location.search)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const pagePostSize = 16

  useEffect(() => {
    ;(async () => {
      await getYourStoryList(currentPage, pagePostSize, order)
    })()
  }, [order, currentPage])

  return (
    <Layout>
      <div className={styles.con}>
        <div className={"title"}>
          <p className={`subtitle`}>당신을 위한 스토리</p>
          <div className={styles.countstory}>
            (전체
            <p className={`red subnotice`}>{total}</p>개 스토리)
          </div>
        </div>
        <OrderMenu
          order={order}
          setOrder={setOrder}
          setCurrentPage={setCurrentPage}
        />

        <div className={`colContainer ${styles.main}`}>
          {yourStoryList.map(post => {
            return (
              <div key={post.story_no}>
                <FreeBadge free={post.free} />
                <Story
                  registerTime={post.publishTime}
                  story_no={post.story_no}
                  title={post.title}
                  introText={post.introText}
                  thumb_title_uuid={post.thumb_title_uuid}
                  cateMain={post.cateMain}
                  cateSub={post.cateSub}
                  Like={post.Like}
                  free={post.free}
                />
              </div>
            )
          })}
        </div>
        <Pagenation
          total={total}
          pagePostSize={pagePostSize}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Layout>
  )
}

export default List
